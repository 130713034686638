
import React from 'react'

function NoPage() {
    return (
        <>

      
            <div className='containerPrincipal'>
                 <h1>NoPage</h1> 
            </div>
          
        
        </>
    );

}

export default NoPage;