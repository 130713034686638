import React, { useEffect, useState } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import GridPrincipalLink from '../../components/GridPrincipalLink'
import { AxiosResponse } from "axios";
import api from "../../Services/api";
import secureLocalStorage from "react-secure-storage";
import ModalPrincipal from '../../components/ModalAlert/modal'
import { useNavigate } from 'react-router';

function Dashboard() {

  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false)
  const [qualAlert, setQualAlert] = useState(<></>)
  const [exibirGrid, setExibirGrid] = useState(<></>);

  useEffect(() => {


    validatToken()



  }, []);


  async function validatToken() {

    let ret: AxiosResponse;
    let valor: any;

    const _user = secureLocalStorage.getItem('t')


    api.defaults.headers.common['Authorization'] = _user as ''

    try {

      ret = await api.get("/status/validar")

      if (ret.data.status === 200) {

        setExibirGrid(<GridPrincipalLink
          iconeExcluir={ModalDeletar}
          th1='Ativo'
          th2='ID'
          th3='Painel'
          th4='Ambiente'
          endPoint='panel/listarpaneluser'
          consult='N'
          col1='1'
          col2='0'
          col3='2'
          col4='9'
          col5='4'
          col6='21'
          ambiente=''
          fromAdd='/user/add'
          fromEdit={`/user/edit`}
          btnDelete='N'
        />)

      }

    } catch (e: any) {

      if (e.response.data.status === 403) {

        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
          <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
        </div>)

        setOpenModal(true)

      }
    }

  }


  function ModalDeletar() {

  }

  function fecharModal() {

    secureLocalStorage.removeItem('t')
    navigate('/login');
    setOpenModal(false)

  }

  return (
    <>
      <Nav />
      <div className="main-content">
        <Header />
        <main>
          <div className='containerPrincipal'>

            <div className='div-titulo-pages'>
              <span className='fa-solid fa-chart-line'/>
              <h1 className='div-titulo-pages-text'>Dashboard</h1>
            </div>
            {exibirGrid}
          </div>
        </main>
      </div>

      <ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
        <div className='container-modal-alert'>
          {qualAlert}
        </div>
      </ModalPrincipal>
    </>
  );

}

export default Dashboard;