import axios from 'axios';

const api = axios.create({
    baseURL: 'https://apicentral.adlim.com.br:3332'
  
})


export default api;

