import React, { PureComponent } from "react";
import ReactPaginate from "react-paginate";
import "./index.css";
import api from "../../Services/api";
import { FcSearch } from "react-icons/fc";
import { FcPlus } from "react-icons/fc";


export class Child extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      offset: 1,
      tableData: [],
      orgtableData: [],
      perPage: 10,
      currentPage: 0,
      pagAtual: 1,
      length: 0,
      endpoint: this.props.endPoint,
      clicked: false,
      atualiza: 0,
      search: '',
      isChecked: [],
      consult: this.props.consult,
      forcePage: 0
    };

    this.handlePageClick = this.handlePageClick.bind(this);
  }



  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
        pagAtual: selectedPage + 1,
        forcePage: selectedPage
      },
      () => {
        this.loadMoreData();
      }
    );


  };

  loadMoreData() {

    const data = this.state.dataT;

    const slice = data.slice(
      this.state.offset,
      this.state.offset + this.state.perPage,

    );

    //////////////////////aqui
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      tableData: slice,
      length: data.length,
    });

  }


  handlePageClick_clear = () => {
    const selectedPage = 0;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
        pagAtual: selectedPage + 1,
        forcePage: selectedPage,
      },
      () => {
        this.loadMoreData();
      }
    );


  };




  handleInputChange = (event) => {
    this.setState({ search: event.target.value });
  };

  do_search = () => {
    this.setState({
      dataT: this.state.orgtableData,
    });
    //------------------------------BUSCAR
    if (this.state.search === '' || this.state.search === undefined || this.state.search === null) {

    } else {

      const temp_rows = this.state.orgtableData.filter(
        (e) => {
          return (
            JSON.stringify(e)
              .toLowerCase()
              .indexOf(this.state.search.toLowerCase()) >= 0
          );
        }
      );

      var slice = temp_rows.slice(
        this.state.offset,
        this.state.offset + this.state.perPage
      );

      this.setState({
        pageCount: Math.ceil(temp_rows.length / this.state.perPage),
        orgtableData: this.state.orgtableData,
        tableData: slice,
        dataT: temp_rows,
        length: temp_rows.length,
        pagAtual: 1,
      });

      this.handlePageClick_clear()
    }


  };

  clear_search = () => {
    //------------------------------LIMPAR
    this.setState({ search: '' });

    var slice = this.state.orgtableData.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );

    this.setState({
      pageCount: Math.ceil(this.state.orgtableData.length / this.state.perPage),
      orgtableData: this.state.orgtableData,
      tableData: slice,
      dataT: this.state.orgtableData,
      length: this.state.orgtableData.length,
      pagAtual: 0,
      forcePage: 0,
    });

    this.handlePageClick_clear();

  };

  componentDidMount() {
    this.getData();
    this.clear_search();
  }

  getData() {
    if (this.props.ambiente !== '') {
      try {
        api.get(this.props.endPoint, {
          params: {
            id: this.props.ambiente
          }
        }).then((res) => {
          var data = res.data.data;

          if (res.data.status === 200) {
            var slice = data.slice(
              this.state.offset,
              this.state.offset + this.state.perPage
            );

            this.setState({
              pageCount: Math.ceil(data.length / this.state.perPage),
              orgtableData: res.data.data,
              tableData: slice,
              dataT: res.data.data,
              length: data.length,
            });

          }
        });


      }
      catch (e) { }
    } else {

      try {
        api.get(this.props.endPoint).then((res) => {
          var data = res.data.data;

          if (res.data.status === 200) {
            var slice = data.slice(
              this.state.offset,
              this.state.offset + this.state.perPage
            );

            this.setState({
              pageCount: Math.ceil(data.length / this.state.perPage),
              orgtableData: res.data.data,
              tableData: slice,
              dataT: res.data.data,
              length: data.length,
            });

          }
        });


      }
      catch (e) {
        
      }
    }
  }


  toggle = (index) => {
    if (this.state.clicked === index) {
      //if clicked question is already active, then close it
      return this.setState({ clicked: null });
    }
    this.setState({ clicked: index });
  };

  handlecheckbox = (e) => {
    const { value, checked } = e.target;

    if (checked) {

      this.setState({
        isChecked: ([...this.state.isChecked, value])
      });
    } else {
      this.setState({
        isChecked: (this.state.isChecked.filter((e) => e !== value))
      });
    }
  }

  render() {
    return (
      <div className="ContainerGrid">
        <div className="ContainerBody">
          <div className="InputBusca">
            <input
              placeholder="Pesquisar..."
              onChange={this.handleInputChange}
              value={this.state.search}
            />
            <div className="icone-buscaT" onClick={this.do_search}>
              <FcSearch className="icone-add" />
            </div>

            <div className="icone-buscaT" onClick={this.clear_search}>
              <i
                className="fa-solid fa-eraser icone-add"
              ></i>
            </div>
          </div>
          <div className="icone-buscaT" onClick={() => this.props.Iconeadd()}>
            <FcPlus className="icone-add" />
          </div>
        </div>

        <div className="ContainerDropdown">
          {this.state.clicked === 1 ? (
            <div className="dropdown">
              <div className="user-filtro">
                <div className="input-box-grid">
                  <span className="details">Categoria</span>
                  <select id="SELCAT" name="SELCAT" key='CAT0'>
                    <option disabled selected>
                      {" "}
                      -- Selecione --{" "}
                    </option>
                  </select>
                </div>

                <div className="input-box-grid">
                  <span className="details">Categoria</span>
                  <select id="SELCAT" name="SELCAT" key='CAT1'>
                    <option disabled selected>
                      {" "}
                      -- Selecione --{" "}
                    </option>
                  </select>
                </div>

                <div className="input-box-grid">
                  <span className="details">Categoria</span>
                  <select id="SELCAT" name="SELCAT" key='CAT2'>
                    <option disabled selected>
                      {" "}
                      -- Selecione --{" "}
                    </option>
                  </select>
                </div>

                <div className="input-box-grid">
                  <span className="details">Categoria</span>
                  <select id="SELCAT" name="SELCAT" key='CAT3'>
                    <option disabled selected>
                      {" "}
                      -- Selecione --{" "}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        {this.state.pageCount > 0 ? (
          <div className="grid-tab-user">
            <table>
              <tbody>
                <tr id="grid-header">
                  <th className="Title">{this.props.th1}</th>
                  <th className="Title">{this.props.th2}</th>
                  <th className="Title">{this.props.th3}</th>


                  <th className="Title">Ações</th>
                </tr>
              </tbody>
              <tbody>
                {this.state.tableData.map((tdata, i) => (
                  <tr className="TR-Coluna" key={Object.values(tdata)[0]}>
                    <td className="ColunaCheck-user" align="center"><input type='checkbox' checked={Object.values(tdata)[this.props.col1]} onChange={() => { }} /></td>
                    <td className="Coluna-padrao-sec" onClick={() => { this.props.PegarValorGrid(Object(tdata)) }}>{Object.values(tdata)[this.props.col2]}</td>
                    <td className="Coluna-padrao-sec" onClick={() => { this.props.PegarValorGrid(Object(tdata)) }}>{Object.values(tdata)[this.props.col3]}</td>
                    <td className="Coluna-icone-btn">
                      <i className="icone-user">
                        <i className="fa fa-pen icone-acao1" onClick={() => { this.props.Iconeditar(Object(tdata)) }}></i>{" "}

                        {this.props.dbtnDelete === 'N' ? (
                          null) :
                          <i className="fa fa-trash icone-acao2" onClick={() => { this.props.iconeExcluir(Object(tdata)) }}  ></i>}


                        {this.state.consult === 'S' ? (
                          <i className="fa fa-link icone-acao3" onClick={() => { this.Deletar(Object(tdata)) }}  ></i>
                        ) : null}
                      </i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
        <div className="ContainerPag">
          <div className="InlineContainery">

            {this.state.pageCount === null || this.state.pageCount === 0 ? (
              <div className="SemResut">
                <h2>
                  Nenhum resultado encontrado.
                </h2>
                <h2>
                  Página 1 de 1
                </h2>
              </div>
            ) : null}


            <div className="InputBusca">
              {this.state.pageCount > 0 ? (
                <div className="Quant">
                  <h2>
                    Página {this.state.pagAtual} de {this.state.pageCount}
                  </h2>
                </div>
              ) : null}

              {this.state.pageCount > 1 ? (
                <ReactPaginate
                  previousLabel={<i className="fas fa-angle-left"></i>}
                  nextLabel={<i className="fas fa-angle-right"></i>}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  forcePage={this.state.forcePage}
                />
              ) : null}
            </div>
          </div>
          {this.state.pageCount > 0 ? (
            <div className="paginate">
              <h2>Total de registros: {this.state.length}</h2>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Child;
