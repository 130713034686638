import React, { useState, useEffect } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import './index.css'
import Select from 'react-select'
import api from '../../Services/api'
import { AxiosResponse } from "axios";
import { MdOutlineAddCircle } from 'react-icons/md';
import GridSecundario from '../../components/GridSecundario'
import FormTipoAgente from '../../components/FormTipoAgente';
import Loading from '../../components/Loading';
import { useNavigate } from 'react-router';
import secureLocalStorage from "react-secure-storage";
import ModalPrincipal from '../../components/ModalAlert/modal'
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'
import DualListBox from "react-dual-listbox";
import 'react-dual-listbox/lib/react-dual-listbox.css';

function CompanyAdd() {
	const navigate = useNavigate();

	const [qualTitulo, setQualTitulo] = useState('')
	const [qualGrid, setQualGrid] = useState(<></>)
	const [openModal, setOpenModal] = useState(false)
	const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)
	const [qualAlert, setQualAlert] = useState(<></>)

	const [active, setActive] = useState(true)
	const [description, setDescription] = useState('');
	const [integrationid, setIntegrationid] = useState('');
	const [dateTimeInsert, setDateTimeInsert] = useState('');
	const [dateTimeUpdate, setDateTimeUpdate] = useState('');


	const [optionsCompany, setOptionsCompany] = useState([])


	useEffect(() => {


		setQualAlert(<div className='div-alert'><Loading /></div>)
		setOpenModal(true)

		let today = new Date();
		let date =
			today.getFullYear() +
			"-" +
			(today.getMonth() + 1).toString().padStart(2, "0") +
			"-" +
			today.getDate().toString().padStart(2, "0");
		let time =
			today.getHours().toString().padStart(2, "0") +
			":" +
			today.getMinutes().toString().padStart(2, "0");
		let JuntarDate = date + "T" + time;
		setDateTimeInsert(JuntarDate)

		validatToken();



	}, []);


	async function validatToken() {

		let ret: AxiosResponse;
		let valor: any;


		const _user = secureLocalStorage.getItem('t')



		api.defaults.headers.common['Authorization'] = _user as ''

		try {

			ret = await api.get("/status/validar")



			if (ret.data.status === 200) {

				GetCompany();
				setOpenModal(false)

			}

		} catch (e: any) {

			if (e.response.data.status === 403) {

				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
					<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
				</div>)

				setOpenModal(true)

			}
		}

	}

	async function GetCompany() {
		let ret: AxiosResponse;
		let valor: any;
		try {
			ret = await api.get("company/listar")
			if (ret.data.status === 200) {

				valor = ret.data.data;
			

				setOptionsCompany(valor)

			}
		} catch (e) {
			setOptionsCompany([])
		}
	}

	function fecharModal() {

		secureLocalStorage.removeItem('t')
		secureLocalStorage.removeItem('acp')
		secureLocalStorage.removeItem('n')
		secureLocalStorage.removeItem('iag')
		navigate('/login');
		setOpenModal(false)

	}



	function ValitarActive() {
		let e = document.getElementById("checkbox-active") as HTMLInputElement;

		if (e.checked) {

			setActive(true)
		} else {

			setActive(false)
		}
	}

	async function confirmarUser() {

		let ret: AxiosResponse;
		let valor: any;

		setQualAlert(<div className='div-alert'><Loading /></div>)
		setOpenModal(true)


		const _user = secureLocalStorage.getItem('t')

		api.defaults.headers.common['Authorization'] = _user as ''

		try {

			ret = await api.get("/status/validar")

			if (ret.data.status === 200) {


				const FiltrarDescription = optionsCompany.filter(
					(e: any) => {
						return e.cpy_description.toLowerCase() === description.toLowerCase()
					}
				);

				const FiltrarIntegrationid = optionsCompany.filter(
					(e: any) => {
						return e.cpy_integrationid.toLowerCase() === integrationid.toLowerCase()
					}
				);


				if (FiltrarDescription.length > 0) {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">A descrição já está em uso.</span></div>
						<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)


				} else
				if (FiltrarIntegrationid.length > 0) {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">O identificador alternativo já está em uso.</span></div>
						<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)


				} else
						if (description === "") {

							setQualAlert(<div className='div-alert'>
								<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
								<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
								<div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
								<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
							</div>)


						}  else
						if (integrationid === "") {

							setQualAlert(<div className='div-alert'>
								<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
								<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
								<div className='div-info-alert'><span className="info-alert">Digite o Identificador alternativo.</span></div>
								<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
							</div>)


						} else {

							const usuario = secureLocalStorage.getItem('use')

							let ret: AxiosResponse;

							const dados = [{
								cpy_active: active,
								cpy_description: description,
								cpy_integrationid: integrationid,
								cpy_id_insert: usuario,
								cpy_id_lastupdate: null
							}];


							try {
								ret = await api.post("company/insert", {
									data: dados
								});
								if (ret.data.status === 200) {


									setQualAlert(<div className='div-alert'>
										<span className="fa-regular fa-circle-check ControleIconAlert"></span>
										<div className='div-info-alert'><span className="info-alert">Ambiente incluido com sucesso.</span></div>
										<div className='btn-alert' onClick={finalizarModal1}><span className="btn-alert-lab">OK</span></div>
									</div>)



								}
							} catch (e: any) {

								setQualAlert(<div className='div-alert'>
									<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
									<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
									<div className='div-info-alert'><span className="info-alert">Não foi possivel concluir o cadastramento.</span></div>
									<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
								</div>)

							}

						}

			}

		} catch (e: any) {

			if (e.response.data.status === 403) {

				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
					<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
				</div>)

			}
		}

	}

	function finalizarModal1() {
		navigate('/company');
	}

	function finalizarModal2() {
		setOpenModal(false)
	}

	function cancelar() {
		navigate('/company');
	}

	return (
		<>
			<Nav />
			<div className="main-content">
				<Header />
				<main>
					<div className='containerPrincipal'>
						<div className="title-Page">
							<div className="title-text">
								<div className="size-x circle">1</div>
								<h2>Informações Gerais</h2>
							</div>
							<hr />
						</div>

						<div className="class-checkbox">
							<input type="checkbox" id="checkbox-active" checked={active} onChange={ValitarActive} />
							<h4 className="lab-ativo">Ativo</h4>
						</div>

						<div className="container-cad-prod8">
							<div id="content1">
								<div className="input-box-ativos">
									<span className="details">Descrição *</span>
									<input
										type="text"
										onChange={(e) => setDescription(e.target.value)}
										value={description}
									/>

								</div>
							</div>
							
							<div id="content2">
							<div className="input-box-ativos">
									<span className="details">Identificador alternativo *</span>
									<input
										type="text"
										onChange={(e) => setIntegrationid(e.target.value)}
										value={integrationid}
									/>

								</div>
						
							</div>

						</div>

						<div className="container-cad-prod8">

							<div id="content1">

								<div className="input-box-ativos">
									<span className="details">Data da Inclusão</span>
									<input
										type="datetime-local"
										value={dateTimeInsert}
										disabled
									/>
								</div>

							</div>


							<div id="content2">
								<div className="input-box-ativos">
									<span className="details">Data da Última Atualização</span>
									<input
										type="datetime-local"
										value={dateTimeUpdate}
										disabled
									/>
								</div>
							</div>

						</div>


						<div className="Bt-button-cad">
							<button type="submit" onClick={confirmarUser}>
								<i className="fa fa-check-circle icon-ok-btn"></i> Salvar
							</button>
							<button type="submit" onClick={cancelar}>
								<i className="fa fa-times icon-cancelar-btn"></i> Cancelar
							</button>
						</div>

					</div>
				</main>
			</div>


			<ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>

				<div className='container-modal-alert'>
					{qualAlert}
				</div>
			</ModalPrincipal>

		</>
	);

}

export default CompanyAdd;