import React, { useState,useEffect } from "react";
import "line-awesome/dist/line-awesome/css/line-awesome.css";
import "./index.css";
import logomenu from "../../img/now.png"
import { Link } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from 'react-router';
import { FaChartArea, FaUser, FaUsers, 
  FaClipboardList, FaCalendarAlt, 
  FaChevronDown, FaWindowRestore, FaCity, FaList, FaExchangeAlt, FaFileAlt,
  FaLayerGroup, FaFileInvoice } from "react-icons/fa";
import { FaMobileScreen, FaListCheck, FaBarcode } from "react-icons/fa6";
import { GiTicket } from "react-icons/gi";
import { MdExitToApp } from "react-icons/md";
import { FaUsersGear } from "react-icons/fa6";
import { FaBoxes } from "react-icons/fa";
import { BsCalendarRangeFill } from "react-icons/bs";


const Nav = () => {
  const navigate = useNavigate();
  
  const [sucesso, setSucesso] = useState('S');
  const [componenteNav, setComponenteNav] = useState(<></>)
  const [acp, setAcp] = useState('');

  useEffect(() => {

    const _user = secureLocalStorage.getItem('acp') as ''

    setAcp(_user)
 
  }, []);

  function navDropdown() {
    if (sucesso === 'N') {
      setSucesso('S')
    } else {
      setSucesso('N')
    }
  }

  function Sair() {
    navigate('/login');
    secureLocalStorage.removeItem('t')
    secureLocalStorage.removeItem('acp')

  }

  
  return (
    <>

    {String(acp) === 'DESENVOLVEDOR' || String(acp) === 'ADMINISTRADOR' ? (
      <>
          <input type="checkbox" id="nav-toggle" />
          {/* Side bar  menu */}
          <div className="sidebar">
            <div className="sidebar-brand">
              <h2><span className='fa fa-pie-chart'></span><span className="Controle">ADLIMNOW BI</span></h2>
            </div>
            <div className="sidebar-menu">
              <ul>

              <li className="side">
                  <Link to='/dashboard'>                 
                    <span className='ControleIcon'><FaChartArea/></span> <span className="lab-icone">Dashboard</span>
                  </Link>
                  <h1 className="Legenda-side">Dashboard</h1>
                </li >

                <li className="side">
                  <Link to="/user">
                  <span className="ControleIcon"><FaUser /></span>
                    <span className="lab-icone">Usuários</span>
                  </Link>
                  <h1 className="Legenda-side">Usuários</h1>
                </li>


                <li className="side">
                  <Link to="/panel">
                  
                  <span className='ControleIcon'><FaClipboardList /></span>
                    <span className="lab-icone">Painel</span>
                  </Link>
                  <h1 className="Legenda-side">Painel</h1>
                </li>

                <li className="side">
                  <Link to="/company">
                  <span className="ControleIcon"><FaCity /></span>{" "}
                    <span className="lab-icone">Ambiente</span>
                  </Link>
                  <h1 className="Legenda-side">Ambiente</h1>
                </li>

                <li className="side" onClick={Sair}>
                  <Link to='/login'>
                    <span className='ControleIcon'><MdExitToApp /></span>
                  <span className="lab-icone">Sair</span> </Link>
                  <h1 className="Legenda-side">Sair</h1>
                </li>

              </ul>
            </div>
          </div>

          </>

    ) : null}


    {String(acp) === 'CONSULTOR' ? (
      <>
          <input type="checkbox" id="nav-toggle" />
          {/* Side bar  menu */}
          <div className="sidebar">
            <div className="sidebar-brand">
              <h2><span className='fa fa-pie-chart'></span><span className="Controle">ADLIMNOW BI</span></h2>
            </div>
            <div className="sidebar-menu">
              <ul>

                <li className="side">
                  <Link to='/dashboard'>
                  <span className='ControleIcon'><FaChartArea/></span> <span className="lab-icone">Dashboard</span>
                  </Link>
                  <h1 className="Legenda-side">Dashboard</h1>
                </li >

                <li className="side" onClick={Sair}>
                  <Link to='/login'>
                    <span className='ControleIcon'><MdExitToApp /></span>
                  <span className="lab-icone">Sair</span> </Link>
                  <h1 className="Legenda-side">Sair</h1>
                </li>
              </ul>
            </div>
          </div>

          </>

    ) : null}













    </>
  );
};

export default Nav;
