import React, { useEffect, useState } from 'react'
import "./index.css";
import { useNavigate } from 'react-router';
import  secureLocalStorage  from  "react-secure-storage";


const Header = (props: any) => {
  const navigate = useNavigate();
  
  const [user, setUser] = useState("")

  useEffect(() => {
    setUser(secureLocalStorage.getItem('nm') as '')
}, []);


  const handleLogoff = () => {
    secureLocalStorage.removeItem('t')
    secureLocalStorage.removeItem('nm')
    secureLocalStorage.removeItem('acp')
    secureLocalStorage.removeItem('use')
    navigate('/login');

  };

  return (
    <div className="main-content">
      <header>
        <h2>
          <label htmlFor="nav-toggle">
            <span className="fa fa-bars ico"></span>
          </label>
        </h2>

        <div className="user-wrapper">
          <div>
            <h4>{user}</h4>
            <small onClick={handleLogoff}>Sair</small>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
