import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet
} from "react-router-dom";

import Dashboard from "../pages/Dashboard"
import Login from "../pages/Login";
import NoPage from "../pages/NoPage"

import Usuarios from '../pages/Usuarios'
import UsuariosAdd from "../pages/UsuariosAdd";
import UsuariosEdit from "../pages/UsuariosEdit";

import Panel from '../pages/Panel'
import PanelAdd from "../pages/PanelAdd";
import PanelEdit from "../pages/PanelEdit";

import Company from '../pages/Company'
import CompanyAdd from "../pages/CompanyAdd";
import CompanyEdit from "../pages/CompanyEdit";

import ProtectedRoutes from "./ProtectedRoutes"
import PublicRoutes from "./PublicRoutes"
import PermissionDenied from "./PermissionDenied"
import InnerContent from "./InnerContent"

export function Router() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ProtectedRoutes />}>
          <Route path="/" element={<InnerContent />}>

            <Route path="/" element={<Navigate replace to="dashboard" />} />
            <Route path="dashboard" element={<Dashboard />} />

            <Route element={<ProtectedRoutes roleRequired="DESENVOLVEDOR,ADMINISTRADOR,CONSULTOR" />}>
              <Route path="/user" element={<Usuarios />} />
            </Route>

            <Route element={<ProtectedRoutes roleRequired="DESENVOLVEDOR,ADMINISTRADOR,CONSULTOR" />}>
              <Route path="/user/add" element={<UsuariosAdd />} />
            </Route>

            
            <Route element={<ProtectedRoutes roleRequired="DESENVOLVEDOR,ADMINISTRADOR,CONSULTOR" />}>
              <Route path="/user/edit/:id" element={<UsuariosEdit />} />
            </Route>

            <Route element={<ProtectedRoutes roleRequired="DESENVOLVEDOR,ADMINISTRADOR,CONSULTOR" />}>
              <Route path="/panel" element={<Panel />} />
            </Route>

            <Route element={<ProtectedRoutes roleRequired="DESENVOLVEDOR,ADMINISTRADOR,CONSULTOR" />}>
              <Route path="/panel/add" element={<PanelAdd />} />
            </Route>

            <Route element={<ProtectedRoutes roleRequired="DESENVOLVEDOR,ADMINISTRADOR,CONSULTOR" />}>
              <Route path="/panel/edit/:id" element={<PanelEdit />} />
            </Route>

            <Route element={<ProtectedRoutes roleRequired="DESENVOLVEDOR,ADMINISTRADOR,CONSULTOR" />}>
              <Route path="/company" element={<Company />} />
            </Route>

            <Route element={<ProtectedRoutes roleRequired="DESENVOLVEDOR,ADMINISTRADOR,CONSULTOR" />}>
              <Route path="/company/add" element={<CompanyAdd />} />
            </Route>
            
            <Route element={<ProtectedRoutes roleRequired="DESENVOLVEDOR,ADMINISTRADOR,CONSULTOR" />}>
              <Route path="/company/edit/:id" element={<CompanyEdit />} />
            </Route>

          </Route>

        </Route>


        <Route path="login" element={<PublicRoutes />}>
          <Route path="/login" element={<Login />} />
        </Route>
        <Route path="/denied" element={<PermissionDenied />} />
        <Route path="*" element={<NoPage />} />
      </Routes>


    </BrowserRouter>
  )
}

export default Router
