import React, { useState, useEffect } from 'react'
import './index.css'
import { useNavigate } from 'react-router';
import { MdEmail, MdLock } from "react-icons/md"
import { HiEye, HiEyeOff } from "react-icons/hi"
import { FaBuilding } from "react-icons/fa"
import api from '../../Services/api'
import { AxiosResponse } from 'axios';
import logo from '../../img/now1.png'
import  secureLocalStorage  from  "react-secure-storage";

function Login() {

   const navigate = useNavigate();
   const [company, setCompany] = useState("")
   const [login, setLogin] = useState("")
   const [senha, setSenha] = useState("")
   const [show, setShow] = useState(false)
   const [sucesso, setSucesso] = useState('');

   const [openModal, setOpenModal] = useState(false)
   const [qualAlert, setQualAlert] = useState(<></>)

   const handleClick = (e: any) => {
      e.preventDefault()
      setShow(!show);
   }

   useEffect(() => {
      localStorage.removeItem("PrivateRoute");
   }, []);

   async function ValidarLogin() {
      let ret: AxiosResponse;

      try {
         ret = await api.get('/user/login', {
            params: {
               login: login,
               senha: senha
            }
         })


         if (ret.data.status === 200) {
     
               secureLocalStorage.setItem('nm', ret.data.data.user[0].use_name)
               secureLocalStorage.setItem('t', ret.data.userToken);
               secureLocalStorage.setItem('acp', ret.data.data.user[0].acp_integrationid);
               secureLocalStorage.setItem('use', ret.data.data.user[0].use_id)

               navigate('/dashboard');

         } 

      } catch (e: any) {
        
            setSucesso('N');
         
      }
   }

   function handleInput(e: any) {
      var ss = e.target.selectionStart;
      var se = e.target.selectionEnd;
      e.target.value = e.target.value.toUpperCase();
      e.target.selectionStart = ss;
      e.target.selectionEnd = se;
   }

   return (
      <div className="login">

         <div className="login-inf">
            <span className='title-informativo'>Seja bem-vindo a sua plataforma de monitoramento!</span>
         </div>

         <div className="login-right">
            <img className="logo" src={logo} alt="logo" />

            <div className="login-loginInputEmail">
               <MdEmail />
               <input
                  placeholder="USUÁRIO"
                  value={login}
                  onChange={e => setLogin(e.target.value)}
               />
            </div>

            <div className="login-loginInputPassword">
               <MdLock />
               <input
                  placeholder="SENHA"
                  type={show ? "text" : "password"}
                  value={senha}
                  onChange={e => setSenha(e.target.value)}
               />
               <div className="login-eye">
                  {show ? (
                     <HiEye
                        size={20}
                        onClick={handleClick}
                     />
                  ) : (
                     <HiEyeOff
                        size={20}
                        onClick={handleClick}
                     />
                  )}
               </div>
            </div>
            <div className='contente-senha'>
               <span className='title-senha'>Esqueceu sua senha?</span>
            </div>
            {sucesso === 'N' ? <div className='alerta'><p>Usuário ou senha inválida.</p></div> : null}
            <button type="submit" onClick={ValidarLogin}>
               Entrar
            </button>

         </div>
      </div>
   )
}

export default Login

