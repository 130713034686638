import React, { useState, useEffect } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import './index.css'
import Select from 'react-select'
import api from '../../Services/api'
import { AxiosResponse } from "axios";
import { MdOutlineAddCircle } from 'react-icons/md';
import GridSecundario from '../../components/GridSecundario'
import FormTipoAgente from '../../components/FormTipoAgente';
import Loading from '../../components/Loading';
import { useNavigate } from 'react-router';
import secureLocalStorage from "react-secure-storage";
import ModalPrincipal from '../../components/ModalAlert/modal'
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'
import DualListBox from "react-dual-listbox";
import 'react-dual-listbox/lib/react-dual-listbox.css';

function UsuariosAdd() {
	const navigate = useNavigate();

	const [qualTitulo, setQualTitulo] = useState('')
	const [qualGrid, setQualGrid] = useState(<></>)
	const [openModal, setOpenModal] = useState(false)
	const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)
	const [qualAlert, setQualAlert] = useState(<></>)

	const [integrationId, setIntegrationId] = useState('')
	const [name, setName] = useState('')
	const [login, setLogin] = useState('')
	const [password, setPassword] = useState('')
	const [observation, setObservation] = useState('')
	const [country, setCountry] = useState('')
	const [state, setState] = useState('')
	const [city, setCity] = useState('')
	const [neighborhood, setNeighborhood] = useState('')
	const [street, setStreet] = useState('')
	const [streetNumber, setStreetNumber] = useState('')
	const [streetNumberCompl, setStreetNumberCompl] = useState('')
	const [zipcode, setZipcode] = useState('')
	const [phone, setPhone] = useState('')
	const [mobilePhone, setMobilePhone] = useState('')
	const [mail, setMail] = useState('')
	const [imeiLastSync, setImeiLastSync] = useState('')
	const [dateHourLastSync, setDateHourLastSync] = useState('')
	const [agg_id, setAgg_id] = useState('')
	const [active, setActive] = useState(true)
	const [lastGeoPosition, setLastGeoPosition] = useState('')
	const [geoPosition, setGeoPosition] = useState('')
	const [batteryLeve, setBatteryLeve] = useState('')
	const [mobilePlatformVersion, setMobilePlatformVersion] = useState('')
	const [mobileUser, setMobileUser] = useState(false)
	const [centerWebUser, setCenterWebUser] = useState(false)
	const [acp_id, setAcp_id] = useState('')
	const [dateTimeInsert, setDateTimeInsert] = useState('');
	const [dateTimeUpdate, setDateTimeUpdate] = useState('');
	const [id_insert, setId_insert] = useState('');
	const [phoneddd, setPhoneddd] = useState('');
	const [phoneddi, setPhoneddi] = useState('');
	const [mobilePhoneddd, setMobilePhoneddd] = useState('');
	const [mobilePhoneddi, setMobilePhoneddi] = useState('');
	const [optionsAgentGroup, setOptionsAgentGroup] = useState([])
	const [optionsAccessProfile, setOptionsAccessProfile] = useState([])

	const [activeDisabled, setActiveDisabled] = useState(false)
	const [nomeDisabled, setNomeDisabled] = useState(false)
	const [loginDisabled, setLoginDisabled] = useState(false)
	const [passwordDisabled, setPasswordDisabled] = useState(false)
	const [mobilePhonedddDisabled, setMobilePhonedddDisabled] = useState(false)
	const [mobilePhoneddiDisabled, setMobilePhoneddiDisabled] = useState(false)
	const [mobilePhoneDisabled, setMobilePhoneDisabled] = useState(false)
	const [phoneddiDisabled, setPhoneddiDisabled] = useState(false)
	const [phonedddDisabled, setPhoneddDDisabled] = useState(false)
	const [phoneDisabled, setPhoneDisabled] = useState(false)
	const [mailDisabled, setMailDisabled] = useState(false)
	const [observationDisabled, setObservationDisabled] = useState(false)
	const [isDisabledAgg_id, setIsDisabledAgg_id] = useState(false)
	const [countryDisabled, setCountryDisabled] = useState(false)
	const [stateDisabled, setStateDisabled] = useState(false)
	const [cityDisabled, setCityDisabled] = useState(false)
	const [neighborhoodDisabled, setNeighborhoodDisabled] = useState(false)
	const [streetDisabled, setStreetDisabled] = useState(false)
	const [streetNumberDisabled, setStreetNumberDisabled] = useState(false)
	const [streetNumberComplDisabled, setStreetNumberComplDisabled] = useState(false)
	const [zipcodeDisabled, setZipcodeDisabled] = useState(false)
	const [isDisabledAcp_id, setIsDisabledAcp_id] = useState(false)
	const [mobileUserDisabled, setMobileUserDisabled] = useState(false)
	const [centerWebUserDisabled, setCenterWebUserDisabled] = useState(false)
	const [confirmarUserDisabled, setConfirmarUserDisabled] = useState(false)
	const [cancelarDisabled, setCancelarDisabled] = useState(false)
	const [integrationIdDisabled, setIntegrationIdDisabled] = useState(false)
	const [dualListBoxDisabled, setDualListBoxDisabled] = useState(false)

	const [optionsUser, setOptionsUser] = useState([])
	const [optionsCompany, setOptionsCompany] = useState([])
	const [tipoUser, setTipoUser] = useState('')
	const [dadosUser, setDadosUser] = useState([]);
	const [userSelecionada, setUserSelecionada] = useState([]);

	useEffect(() => {


		setQualAlert(<div className='div-alert'><Loading /></div>)
		setOpenModal(true)

		let today = new Date();
		let date =
			today.getFullYear() +
			"-" +
			(today.getMonth() + 1).toString().padStart(2, "0") +
			"-" +
			today.getDate().toString().padStart(2, "0");
		let time =
			today.getHours().toString().padStart(2, "0") +
			":" +
			today.getMinutes().toString().padStart(2, "0");
		let JuntarDate = date + "T" + time;
		setDateTimeInsert(JuntarDate)

		validatToken();



	}, []);


	async function GetUser() {
		let ret: AxiosResponse;
		let valor: any;
		try {
			ret = await api.get("user/listar")
			if (ret.data.status === 200) {
				valor = ret.data.data;

				setOptionsUser(valor)

			}
		} catch (e) {
			setOptionsUser([])
		}
	}



	async function GetCompany() {
		let ret: AxiosResponse;
		let valor: any;
		try {
			ret = await api.get("company/listar")
			if (ret.data.status === 200) {
				valor = ret.data.data;

				setOptionsCompany(valor)

			}
		} catch (e) {
			setOptionsUser([])
		}
	}

	async function validatToken() {

		let ret: AxiosResponse;
		let valor: any;


		const _user = secureLocalStorage.getItem('t')

		

		api.defaults.headers.common['Authorization'] = _user as ''

		try {

			ret = await api.get("/status/validar")

	
			
			if (ret.data.status === 200) {

				await GetUser();
				await GetAgentGroup();
				await GetAccessProfile();
				await GetCompany();
				setOpenModal(false)

			}

		} catch (e: any) {

			if (e.response.data.status === 403) {

				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
					<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
				</div>)

				setOpenModal(true)

			}
		}

	}


	function fecharModal() {

		secureLocalStorage.removeItem('t')
		secureLocalStorage.removeItem('acp')
		secureLocalStorage.removeItem('n')
		secureLocalStorage.removeItem('iag')
		navigate('/login');
		setOpenModal(false)

	}

	async function GetAccessProfile() {
		let ret: AxiosResponse;
		let valor: any;
		try {
			ret = await api.get("accessprofile/listar")
			if (ret.data.status === 200) {
				valor = ret.data.data;
				const arr = [] as any;
				let result = valor;
				result.map((user: any) => {
					return arr.push({ value: String(user.acp_id), label: user.acp_description });
				});
				setOptionsAccessProfile(arr)

			}
		} catch (e) {
			setOptionsAccessProfile([])
		}
	}

	async function GetAgentGroup() {
		let ret: AxiosResponse;
		let valor: any;
		try {
			ret = await api.get("agentgroup/listar")
			if (ret.data.status === 200) {
				valor = ret.data.data;
				const arr = [] as any;
				let result = valor;
				result.map((user: any) => {
					return arr.push({ value: String(user.agg_id), label: user.agg_description });
				});
				setOptionsAgentGroup(arr)

			}
		} catch (e) {
			setOptionsAgentGroup([])
		}
	}


	function ValitarActive() {
		let e = document.getElementById("checkbox-active") as HTMLInputElement;

		if (e.checked) {

			setActive(true)
		} else {

			setActive(false)
		}
	}

	function ValitarActivemobileuser() {
		let e = document.getElementById("checkbox-mobileuser") as HTMLInputElement;

		if (e.checked) {

			setMobileUser(true)
		} else {

			setMobileUser(false)
		}
	}

	function ValitarActiveCenterwebuser() {
		let e = document.getElementById("checkbox-centerwebuser") as HTMLInputElement;

		if (e.checked) {

			setCenterWebUser(true)
		} else {

			setCenterWebUser(false)
		}
	}

	const customStyles = {
		control: (provided: any, state: any) => ({
			...provided,
			background: '#fff',
			minHeight: '30px',
			height: '30px',
			borderColor: state.isFocused ? 'grey' : '#92989e',

		}),


		valueContainer: (provided: any, state: any) => ({
			...provided,
			height: '30px',
			padding: '0 6px'
		}),

		input: (provided: any, state: any) => ({
			...provided,
			margin: '0px',
			fontSize: 14,

		}),
		indicatorsContainer: (provided: any, state: any) => ({
			...provided,
			height: '30px',
		}),
	};


	async function ChamarModalAgentGroup(tipoModal: any) {

		let ret: AxiosResponse;
		let valor: any;


		const _user = secureLocalStorage.getItem('t')

		api.defaults.headers.common['Authorization'] = _user as ''

		try {

			ret = await api.get("/status/validar")

			if (ret.data.status === 200) {

				secureLocalStorage.setItem('t', ret.data.data);
				secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
				secureLocalStorage.setItem('n', ret.data.decodedToken.name);
				secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
				secureLocalStorage.setItem('cy', ret.data.decodedToken.company);

				if (tipoUser === 'DESENVOLVEDOR' || tipoUser === 'ADMINISTRADOR') {

					if (tipoModal === 'Função') {
						setOpenModalIncluirNovo(true)
						sessionStorage.removeItem("dadosTipoAgente");
						setQualTitulo('Cadastrar Função')
						setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroAgentGroup} Iconeditar={EditarAgentGroup} iconeExcluir={AgentGroupDeletar}
							th1='Ativo'
							th2='Descrição'
							th3='Identificador Alternativo'
							th4='Subcategoria'
							th5='Data da Inclusão'
							th6='Data Atualização'
							endPoint='agentgroup/listar'
							consult='N'
							col1='1'
							col2='2'
							col3='3'
							ambiente='%'
						/></>)
						)
					}
				}


			}

		} catch (e: any) {

			if (e.response.data.status === 403) {

				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
					<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
				</div>)

				setOpenModal(true)

			}
		}




	}

	function ChamarCadastroAgentGroup() {
		sessionStorage.removeItem("dadosTipoAgente");
		setQualGrid(<><FormTipoAgente voltar={BtnVoltarAgentGroup} /></>)
	}

	function BtnVoltarAgentGroup() {

		setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroAgentGroup} Iconeditar={EditarAgentGroup} iconeExcluir={AgentGroupDeletar}
			th1='Ativo'
			th2='Descrição'
			th3='Identificador Alternativo'
			th4='Subcategoria'
			th5='Data da Inclusão'
			th6='Data Atualização'
			endPoint='agentgroup/listar'
			consult='N'
			col1='1'
			col2='2'
			col3='3'
			ambiente='%'
		/></>))

		GetAgentGroup()
	}

	async function EditarAgentGroup(obj: any) {
		sessionStorage.setItem("dadosTipoAgente", JSON.stringify(obj));
		setQualGrid(<><FormTipoAgente voltar={BtnVoltarAgentGroup} /></>)
	}

	function AgentGroupDeletar(obj: any) {
		let ret: AxiosResponse;

		setOpenModal(true)

		setQualAlert(<div className='div-alert'>
			<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
			<div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
			<div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

			<div className='btn-alert-div'>
				<span className='btn-alert-esc' onClick={() => deletarAgentGroup(obj.agg_id)}>
					<span className="btn-alert-lab">SIM</span>
				</span>
				<span className='btn-alert-dir' onClick={finalizarModal2}>
					<span className="btn-alert-lab">NÃO</span>
				</span>
			</div>
		</div>)


	}

	async function deletarAgentGroup(id: any) {
		setQualGrid(<><Loading /></>)
		let ret: AxiosResponse;
		try {
			ret = await api.delete("agentgroup/delete", {
				data: { id: id }
			});
			if (ret.data.status === 200) {

				setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroAgentGroup} Iconeditar={EditarAgentGroup} iconeExcluir={AgentGroupDeletar}
					th1='Ativo'
					th2='Descrição'
					th3='Identificador Alternativo'
					th4='Subcategoria'
					th5='Data da Inclusão'
					th6='Data Atualização'
					endPoint='agentgroup/listar'
					consult='N'
					col1='1'
					col2='2'
					col3='3'
					ambiente='%'
				/></>))
				GetAgentGroup()
			}
		} catch (e: any) {
			setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroAgentGroup} Iconeditar={EditarAgentGroup} iconeExcluir={AgentGroupDeletar}
				th1='Ativo'
				th2='Descrição'
				th3='Identificador Alternativo'
				th4='Subcategoria'
				th5='Data da Inclusão'
				th6='Data Atualização'
				endPoint='agentgroup/listar'
				consult='N'
				col1='1'
				col2='2'
				col3='3'
				ambiente='%'
			/></>))
		}

		setOpenModal(false)
	}

	function PegarValorGrid(valor: any) {

		setAgg_id(String(valor.agg_id))
		setOpenModalIncluirNovo(false)

	}


	async function confirmarUser() {

		let ret: AxiosResponse;
		let valor: any;

		setQualAlert(<div className='div-alert'><Loading /></div>)
		setOpenModal(true)


		const _user = secureLocalStorage.getItem('t')
		const usuario = secureLocalStorage.getItem('use')

		api.defaults.headers.common['Authorization'] = _user as ''

		try {

			ret = await api.get("/status/validar")

			if (ret.data.status === 200) {


				const FiltrarLogin = optionsUser.filter(
					(e: any) => {
						return e.use_login.toLowerCase() === login.toLowerCase()
					}
				);

				const FiltrarNome = optionsUser.filter(
					(e: any) => {
						return e.use_name.toLowerCase() === name.toLowerCase()
					}
				);



				if (FiltrarNome.length > 0) {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">O nome já está em uso.</span></div>
						<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)


				} else
					if (FiltrarLogin.length > 0) {

						setQualAlert(<div className='div-alert'>
							<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
							<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
							<div className='div-info-alert'><span className="info-alert">O login já está em uso.</span></div>
							<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
						</div>)


					} else
						if (name === "") {
							
							setQualAlert(<div className='div-alert'>
								<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
								<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
								<div className='div-info-alert'><span className="info-alert">Digite o nome.</span></div>
								<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
							</div>)


						} else if (login === "") {

							setQualAlert(<div className='div-alert'>
								<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
								<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
								<div className='div-info-alert'><span className="info-alert">Digite o login.</span></div>
								<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
							</div>)


						} else if (password === "") {

							setQualAlert(<div className='div-alert'>
								<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
								<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
								<div className='div-info-alert'><span className="info-alert">Digite a senha.</span></div>
								<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
							</div>)

						} else if (mail === "") {

							setQualAlert(<div className='div-alert'>
								<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
								<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
								<div className='div-info-alert'><span className="info-alert">Digite o e-mail.</span></div>
								<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
							</div>)



						} else if(userSelecionada.length === 0 ){
							
							setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Selecione ao menos um ambiente.</span></div>
						<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)

						}	
						else if (acp_id === "") {
							setQualAlert(<div className='div-alert'>
								<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
								<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
								<div className='div-info-alert'><span className="info-alert">Selecione o perfil de acesso.</span></div>
								<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
							</div>)

						} else {
							let ret: AxiosResponse;

							const dados = [{
								use_integrationid: integrationId,
								use_name: name,
								use_login: login,
								use_password: password,
								use_observation: observation,
								use_country: country,
								use_state: state,
								use_city: city,
								use_cityneighborhood: neighborhood,
								use_street: street,
								use_streetnumber: streetNumber,
								use_streetcomplement: streetNumberCompl,
								use_zipcode: zipcode,
								use_phone: phone,
								use_mobilephone: mobilePhone,
								use_email: mail,
								agg_id: agg_id || null,
								use_active: active,
								acp_id: acp_id || null,
								use_id_insert: usuario,
								use_id_lastupdate: null,
								use_phoneddd: phoneddd,
								use_phoneddi: phoneddi,
								use_mobilephoneddd: mobilePhoneddd,
								use_mobilephoneddi: mobilePhoneddi,		
							}];


							try {
								ret = await api.post("user/insert", {
									data: dados,
									dadosuser: userSelecionada
								});
								if (ret.data.status === 200) {


									setQualAlert(<div className='div-alert'>
										<span className="fa-regular fa-circle-check ControleIconAlert"></span>
										<div className='div-info-alert'><span className="info-alert">Usuário foi incluido com sucesso.</span></div>
										<div className='btn-alert' onClick={finalizarModal1}><span className="btn-alert-lab">OK</span></div>
									</div>)



								}
							} catch (e: any) {

								setQualAlert(<div className='div-alert'>
									<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
									<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
									<div className='div-info-alert'><span className="info-alert">Não foi possivel concluir o cadastramento.</span></div>
									<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
								</div>)


							}
						

						}



		





						

			}

		} catch (e: any) {

			if (e.response.data.status === 403) {

				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
					<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
				</div>)


			}
		}


	}


	function finalizarModal1() {
		navigate('/user');
	}

	function finalizarModal2() {
		setOpenModal(false)
	}

	function cancelar() {
		navigate('/user');
	}

	const maskOnlyNumbers = (value: any) => {
		return value.replace(/\D/g, "");
	};

	function validarPerfil(id: any) {


		if (id === '6') {

			setAcp_id(id)
			setCenterWebUser(false)
			setMobileUser(true)
			setMobileUserDisabled(true)
			setCenterWebUserDisabled(true)

		} else if (id === '') {

			setCenterWebUser(false)
			setMobileUser(false)
			setMobileUserDisabled(false)
			setCenterWebUserDisabled(false)
			setAcp_id(id)

		} else if (id === '5') {

			setAcp_id(id)
			setCenterWebUser(true)
			setMobileUser(false)
			setMobileUserDisabled(true)
			setCenterWebUserDisabled(true)

		} else if (id === '3') {

			setAcp_id(id)
			setCenterWebUser(true)
			setMobileUser(false)
			setMobileUserDisabled(true)
			setCenterWebUserDisabled(true)

		} else if (id === '4') {

			setAcp_id(id)
			setCenterWebUser(true)
			setMobileUser(false)
			setMobileUserDisabled(true)
			setCenterWebUserDisabled(true)

		} else if (id === '1' || id === '2') {

			setAcp_id(id)
			setCenterWebUser(true)
			setMobileUser(false)
			setCenterWebUserDisabled(true)
			setMobileUserDisabled(false)
			setCenterWebUserDisabled(true)

		} else {
			setCenterWebUser(false)
			setMobileUser(false)
			setMobileUserDisabled(false)
			setCenterWebUserDisabled(false)
			setAcp_id(id)

		}
	}

	function fecharOpenModalIncluirNovo() {
		setOpenModalIncluirNovo(false)
	}


	let options = optionsCompany.map((element: any) => {
		return { value: String(element.cpy_id), label: element.cpy_description }
	});

	
	function valor(selected: any) {

		setUserSelecionada(selected)

	}

	return (
		<>
			<Nav />
			<div className="main-content">
				<Header />
				<main>
					<div className='containerPrincipal'>
						<div className="title-Page">
							<div className="title-text">
								<div className="size-x circle">1</div>
								<h2>Informações Gerais</h2>
							</div>
							<hr />
						</div>

						<div className="class-checkbox">
							<input type="checkbox" id="checkbox-active" checked={active} onChange={ValitarActive} disabled={activeDisabled} />
							<h4 className="lab-ativo">Ativo</h4>
						</div>

						<div className="container-cad-prod1">
							<div id="content1">
								<div className="input-box-ativos">
									<span className="details">Nome *</span>
									<input
										type="text"
										onChange={(e) => setName(e.target.value)}
										value={name}
										disabled={nomeDisabled}
									/>

								</div>
							</div>
							<div id="content2">
								<div className="input-box-ativos">
									<span className="details">Login *</span>
									<input
										type="text"
										onChange={(e) => setLogin(e.target.value)}
										value={login}
										disabled={loginDisabled}
									/>
								</div>
							</div>
							<div id="content3">
								<div className="input-box-ativos">
									<span className="details">Senha *</span>
									<input
										type="password"
										onChange={(e) => setPassword(e.target.value)}
										value={password}
										disabled={passwordDisabled}
									/>
								</div>
							</div>
						</div>

						<div className="container-cad-prod10">
							<div id="content1">
								<div className="input-box-ativos">
									<span className="details">
										ID para Integração</span>
									<input
										type="text"
										onChange={(e) => setIntegrationId(e.target.value)}
										value={integrationId}
										disabled={integrationIdDisabled}
									/>
								</div>
							</div>

							<div id="content2">
								<div className="input-box-ativos">
									<span className="details">DDI</span>
									<input
										maxLength={3}
										type="text"
										onChange={(e) => setMobilePhoneddi(maskOnlyNumbers(e.target.value))}
										value={mobilePhoneddi}
										disabled={mobilePhoneddiDisabled}
									/>
								</div>
							</div>


							<div id="content3">
								<div className="input-box-ativos">
									<span className="details">DDD</span>
									<input
										maxLength={3}
										type="text"
										onChange={(e) => setMobilePhoneddd(maskOnlyNumbers(e.target.value))}
										value={mobilePhoneddd}
										disabled={mobilePhonedddDisabled}
									/>
								</div>
							</div>

							<div id="content4">
								<div className="input-box-ativos">
									<span className="details">Celular</span>
									<input
										maxLength={18}
										type="text"
										onChange={(e) => setMobilePhone(maskOnlyNumbers(e.target.value))}
										value={mobilePhone}
										disabled={mobilePhoneDisabled}
									/>
								</div>
							</div>

							<div id="content5">
								<div className="input-box-ativos">
									<span className="details">DDI</span>
									<input
										maxLength={3}
										type="text"
										onChange={(e) => setPhoneddi(maskOnlyNumbers(e.target.value))}
										value={phoneddi}
										disabled={phoneddiDisabled}
									/>
								</div>
							</div>

							<div id="content6">
								<div className="input-box-ativos">
									<span className="details">DDD</span>
									<input
										maxLength={3}
										type="text"
										onChange={(e) => setPhoneddd(maskOnlyNumbers(e.target.value))}
										value={phoneddd}
										disabled={phonedddDisabled}
									/>
								</div>
							</div>

							<div id="content7">
								<div className="input-box-ativos">
									<span className="details">Telefone</span>
									<input
										maxLength={18}
										type="text"
										onChange={(e) => setPhone(maskOnlyNumbers(e.target.value))}
										value={phone}
										disabled={phoneDisabled}
									/>
								</div>
							</div>

						</div>

						<div className="container-cad-prod22">

							<div id="content1">
								<div className="input-box-ativos">
									<span className="details">E-mail *</span>
									<input
										type="text"
										onChange={(e) => setMail(e.target.value)}
										value={mail}
										disabled={mailDisabled}
									/>
								</div>
							</div>

							<div id="content2">
								<div className="select-box-ativos-select">
								
										<span className="details">Função</span>
										
								
									<Select
										placeholder={'Selecione...'}
										options={optionsAgentGroup}
										styles={customStyles}
										value={optionsAgentGroup.filter(function (option: any) {
											return option.value === agg_id;
										})}
										onChange={(options: any) =>
											!options ? setAgg_id("") : setAgg_id(options.value)
										}
										isClearable
										noOptionsMessage={() => "Não encontrado..."}
										theme={(theme: any) => ({
											...theme,
											borderRadius: 4,
											colors: {
												...theme.colors,
												primary25: '#ebebeb',
												primary: 'black',
											},
										})}
										isDisabled={isDisabledAgg_id}
									/>
								</div>
							</div>
							
							<div id="content3">
								<div className="input-box-ativos">
									<span className="details">Data da Inclusão</span>
									<input
										type="datetime-local"
										value={dateTimeInsert}
										disabled
									/>
								</div>
							</div>

							<div id="content4">
								<div className="input-box-ativos">
									<span className="details">Data da Última Atualização</span>
									<input
										type="datetime-local"
										value={dateTimeUpdate}
										disabled
									/>
								</div>
							</div>
						</div>

						<div className="container-cad-prod3">
							<div className="textareaBox-ativo">
								<span className="details">Observação</span>
								<textarea
									onChange={(e) => setObservation(e.target.value)}
									value={observation}
									disabled={observationDisabled}
								/>
							</div>
						</div>


						<div className="title-Page">
							<div className="title-text">
								<div className="size-x circle">2</div>
								<h2>Ambientes</h2>
							</div>
							<hr />
						</div>

						<div className='DualListBox'>
							<DualListBox
								options={options}
								selected={userSelecionada}
								onChange={valor}
								icons={{
									moveLeft: "<",
									moveAllLeft: "<<",
									moveRight: ">",
									moveAllRight: ">>"
								}}
								preserveSelectOrder={true}
								disabled={dualListBoxDisabled}
							/>
						</div>


						<div className="title-Page">
							<div className="title-text">
								<div className="size-x circle">3</div>
								<h2>Endereço</h2>
							</div>
							<hr />
						</div>

						<div className="container-cad-prod6">

							<div id="content1">
								<div className="input-box-ativos">
									<span className="details">País</span>
									<input
										type="text"
										onChange={(e) => setCountry(e.target.value)}
										value={country}
										disabled={countryDisabled}
									/>
								</div>
							</div>
							<div id="content2">
								<div className="input-box-ativos">
									<span className="details">Estado</span>
									<input
										type="text"
										onChange={(e) => setState(e.target.value)}
										value={state}
										disabled={stateDisabled}
									/>
								</div>
							</div>
							<div id="content3">
								<div className="input-box-ativos">
									<span className="details">Cidade</span>
									<input
										type="text"
										onChange={(e) => setCity(e.target.value)}
										value={city}
										disabled={cityDisabled}
									/>
								</div>
							</div>
							<div id="content4">
								<div className="input-box-ativos">
									<span className="details">Bairro</span>
									<input
										type="text"
										onChange={(e) => setNeighborhood(e.target.value)}
										value={neighborhood}
										disabled={neighborhoodDisabled}
									/>
								</div>
							</div>
						</div>

						<div className="container-cad-prod12">
							<div id="content1">
								<div className="input-box-ativos">
									<span className="details">Logradouro</span>
									<input
										type="text"
										onChange={(e) => setStreet(e.target.value)}
										value={street}
										disabled={streetDisabled}
									/>
								</div>
							</div>
							<div id="content2">
								<div className="input-box-ativos">
									<span className="details">Número</span>
									<input
										type="text"
										onChange={(e) => setStreetNumber(e.target.value)}
										value={streetNumber}
										disabled={streetNumberDisabled}
									/>
								</div>
							</div>
							<div id="content3">
								<div className="input-box-ativos">
									<span className="details">Complemento</span>
									<input
										type="text"
										onChange={(e) => setStreetNumberCompl(e.target.value)}
										value={streetNumberCompl}
										disabled={streetNumberComplDisabled}
									/>
								</div>
							</div>
							<div id="content4">
								<div className="input-box-ativos">
									<span className="details">CEP</span>
									<input
										type="text"
										onChange={(e) => setZipcode(e.target.value)}
										value={zipcode}
										disabled={zipcodeDisabled}
									/>
								</div>
							</div>
						</div>

						<div className="title-Page">
							<div className="title-text">
								<div className="size-x circle">4</div>
								<h2>Permissões</h2>
							</div>
							<hr />
						</div>

						<div className="container-cad-prod21">

							<div id="content1">
								<div className="select-box-ativos-select">
									<span className="details">Perfil de Acesso *</span>
									<Select
										maxMenuHeight={100}
										placeholder={'Selecione...'}
										options={optionsAccessProfile}
										styles={customStyles}
										value={optionsAccessProfile.filter(function (option: any) {
											return option.value === acp_id;
										})}
										onChange={(options: any) =>
											!options ? validarPerfil("") : validarPerfil(options.value)
										}
										isClearable
										noOptionsMessage={() => "Não encontrado..."}
										theme={(theme: any) => ({
											...theme,
											borderRadius: 4,
											colors: {
												...theme.colors,
												primary25: '#ebebeb',
												primary: 'black',
											},
										})}
										isDisabled={isDisabledAcp_id}
									/>
								</div>
							</div>

						</div>



						<div className="Bt-button-cad">
							<button type="submit" onClick={confirmarUser} disabled={confirmarUserDisabled}>
								<i className="fa fa-check-circle icon-ok-btn"></i> Salvar
							</button>
							<button type="submit" onClick={cancelar} disabled={cancelarDisabled}>
								<i className="fa fa-times icon-cancelar-btn"></i> Cancelar
							</button>
						</div>

					</div>
				</main>
			</div>

			<ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
				<>
					<div className='title-modal-incluir'>
						<h1 className="modal-IncluirNovo-title">{qualTitulo}</h1>
						<button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close" onClick={fecharOpenModalIncluirNovo} />
					</div>

					<div className='container-modal-incluir'>
						{qualGrid}
					</div>
				</>
			</ModalIncluirNovo>

			<ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>

				<div className='container-modal-alert'>
					{qualAlert}
				</div>
			</ModalPrincipal>

		</>
	);

}

export default UsuariosAdd;